.section-1 {
	position: relative;
}

.section-1 #pic-1 {
	width: 100%;
}

.section-1 #logo-1 {
	position: absolute;
	top: 15%;
	left: 30px;
	width: 33%;
}

.section-1 .employee-name {
	position: absolute;
	bottom: 40%;
	left: 30px;
	font-weight: bold;
	font-size: 200%;
	line-height: 1.2;
	inline-size: 160px;
	overflow-wrap: break-word;
}

.section-1 .employee-position {
	position: absolute;
	top: 65%;
	left: 30px;
	font-size: 120%;
	line-height: 1.2;
	inline-size: 160px;
	overflow-wrap: break-word;
}

.section-2 {
	background-image: url('../bg/Rectangle 7.png');
	margin-top: -2px;
}

.section-2 .wrapper {
	width: 100%;
	padding: 0px 30px 20px;
}

.section-3 {
	width: 100%;
	position: relative;
	margin-top: -2px;
}

.box-video {
	margin-top: 30px;
	position: relative;
	padding: 0px 30px;
	margin-bottom: -70px;
}

.box-video .wrapper {
	border: 1px solid #afadad;
	background-color: white;
	position: relative;
	z-index: 9999;
}

.box-video .wrapper .video {
	height: 200px;
	background-color: white;
	position: relative;
}

.section-5 {
	background-image: url('../bg/BG.png');
	padding: 20px 0px;
	text-align: center;
}

.section-7 {
	background-image: url('../bg/Frame 303.png');
	margin-top: -30px;
	padding: 30px 0px;
}

.section-8 {
	background-image: url('../bg/Rectangle 8.png');
	background-repeat: no-repeat;
	background-size: 100%;
	color: white;
	text-align: center;
	padding: 10px 0px;
	font-size: 8pt;
}

.table-vcard tr {
	line-height: 27px;
}

.table-vcard tr td {
	color: #f0eeee;
	font-size: 1.2em;
}

.table-vcard tr td:first-child {
	border-left: 1px solid #f0eeee;
}

.table-vcard .icon {
	vertical-align: top;
	padding: 0 15px;
}

.gutter-custom {
	--bs-gutter-x: 1.8rem;
}

.social-media {
	height: 80px;
	text-align: center;
	position: relative;
}

.logo-social-media {
	width: 40%;
}

.text-social-media {
	padding: 0;
}

.text-social-media div:nth-child(2) {
	font-size: 6pt;
}

@media only screen and (min-width: 300px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 45%;
	}

	.text-social-media div:nth-child(2) {
		font-size: 5pt;
	}
}

@media only screen and (min-width: 400px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 35%;
	}

	.text-social-media div:nth-child(2) {
		font-size: 5pt;
	}
}

@media only screen and (min-width: 500px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 30%;
	}
}

@media only screen and (min-width: 890px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 30%;
	}
}

@media only screen and (min-width: 1200px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 40%;
	}
}

@media only screen and (min-width: 1600px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 35%;
	}
}

@media only screen and (min-width: 1900px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 30%;
	}
}

@media only screen and (min-width: 2000px) {
	/* For mobile phones: */
	.logo-social-media {
		width: 20%;
	}
}
